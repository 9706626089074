<template>
  <div class="seed-nft-view">
    <div class="swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="name" v-show="activeIndex==0">
              Free Seed NFT
            </div>

            <img src="../assets/img/1@2x.png" alt="">
            <div class="canM">
              You Mint <strong>{{seedLength}}</strong> Seed NFT
            </div>
            <button class="button" @click="mint(1)">
              FREE MINT
            </button>
          </div>
          <div class="swiper-slide">
            <div class="name">
              Free Angel NFT
            </div>

            <img src="../assets/img/3.png" alt="">
            <div class="canM">
              You Mint <strong>{{AngleLength}}</strong> Angel NFT
            </div>
            <button class="button" @click="mint(2)">
              FREE MINT
            </button>
          </div>
          <div class="swiper-slide">
            <div class="name">
              Free Master NFT
            </div>
            <img src="../assets/img/2.png" alt="">
            <div class="canM">
              You Mint <strong>{{maskterLength}}</strong> Master NFT
            </div>
            <button class="button" @click="mint(3)">
              FREE MINT
            </button>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>

    </div>
    <div class="seed-pool">
      <div class="title" style="margin-top: 40px" v-show="activeIndex==0">
        Seed Pool
      </div>
      <div class="title" style="margin-top: 40px" v-show="activeIndex==1">
        Angel Pool
      </div>
      <div class="title" style="margin-top: 40px" v-show="activeIndex==2">
        Master Pool
      </div>
      <el-progress v-show="activeIndex==0" class="progress-pie-chart" type="circle" :percentage="parseFloat(100*ContractBalance1/147000000).toFixed(2)">
        <div class="ppc-percents">
          <div class="pcc-percents-wrapper">
            <span class="value" v-show="activeIndex==0">
              {{ ContractBalance1.toFixed(2) }}
            </span>
            <span class="value" v-show="activeIndex==1">
              {{ ContractBalance2.toFixed(2) }}
            </span>
            <span class="value" v-show="activeIndex==2">
              {{ ContractBalance3.toFixed(2) }}
            </span>
            <div class="coin">ACE</div>
          </div>
        </div>
      </el-progress>
      <el-progress v-show="activeIndex==1" class="progress-pie-chart" type="circle" :percentage="parseFloat(100*ContractBalance2/441000000).toFixed(2)">
        <div class="ppc-percents">
          <div class="pcc-percents-wrapper">
            <span class="value" v-show="activeIndex==0">
              {{ ContractBalance1.toFixed(2) }}
            </span>
            <span class="value" v-show="activeIndex==1">
              {{ ContractBalance2.toFixed(2) }}
            </span>
            <span class="value" v-show="activeIndex==2">
              {{ ContractBalance3.toFixed(2) }}
            </span>
            <div class="coin">ACE</div>
          </div>
        </div>
      </el-progress>
      <el-progress v-show="activeIndex==2" class="progress-pie-chart" type="circle" :percentage="parseFloat(100*ContractBalance3/882000000).toFixed(2)">
        <div class="ppc-percents">
          <div class="pcc-percents-wrapper">
            <span class="value" v-show="activeIndex==0">
              {{ ContractBalance1.toFixed(2) }}
            </span>
            <span class="value" v-show="activeIndex==1">
              {{ ContractBalance2.toFixed(2) }}
            </span>
            <span class="value" v-show="activeIndex==2">
              {{ ContractBalance3.toFixed(2) }}
            </span>
            <div class="coin">ACE</div>
          </div>
        </div>
      </el-progress>
      <div class="flex-box">
        <div class="name">
          Total Mining
        </div>
        <div class="value" v-show="activeIndex==0">
          {{ ContractBalance1.toFixed(2) }}
        </div>
        <div class="value" v-show="activeIndex==1">
          {{ ContractBalance2.toFixed(2) }}
        </div>
        <div class="value" v-show="activeIndex==2">
          {{ ContractBalance3.toFixed(2) }}
        </div>
      </div>
      <div class="end-time" v-show="activeIndex==0">
        {{ currentTime1 }}
      </div>
      <div class="end-time" v-show="activeIndex==1">
        {{ currentTime2 }}
      </div>
      <div class="end-time" v-show="activeIndex==2">
        {{ currentTime3 }}
      </div>
      <button class="button" @click="approve">
        Approve
      </button>
      <button class="button" @click="stake">
        Stake
      </button>
    </div>
    <div class="seed-nft">
      <div class="title" v-show="activeIndex==0">
        Seed NFT
      </div>
      <div class="title" v-show="activeIndex==1">
        Angel NFT
      </div>
      <div class="title" v-show="activeIndex==2">
        Master NFT
      </div>
      <div class="reward"  v-show="activeIndex==0">
        Your stake reward <strong>{{parseFloat(seedUserR).toFixed(4)}}</strong> ACE.
      </div>
      <div class="reward"  v-show="activeIndex==1">
        Your stake reward <strong> {{parseFloat(AngleUserR).toFixed(4)}}</strong> ACE.
      </div>
      <div class="reward"  v-show="activeIndex==2">
        Your stake reward <strong>  {{parseFloat(masterUserR).toFixed(4)}}</strong> ACE.
      </div>
      <div class="nft-box" v-show="activeIndex==0">
        <div class="nft-item" v-show="seedStake1">
          <img src="../assets/img/1@2x.png" alt="">
          <div class="name">
            ACE #{{ seedStake1 }}
          </div>
          <button class="button" @click="withdraw(seedStake1)">
            WITHDRAW
          </button>
        </div>
        <div class="nft-item" v-show="seedStake2">
          <img src="../assets/img/1@2x.png" alt="">
          <div class="name">
            ACE #{{ seedStake2 }}
          </div>
          <button class="button" @click="withdraw(seedStake2)">
            WITHDRAW
          </button>
        </div>
      </div>
      <div class="nft-box" v-show="activeIndex==2">
        <div class="nft-item" v-show="MasterStake1">
          <img src="../assets/img/2.png" alt="">
          <div class="name">
            ACE #{{ MasterStake1 }}
          </div>
          <button class="button" @click="withdraw(MasterStake1)">
            WITHDRAW
          </button>
        </div>
        <div class="nft-item" v-show="MasterStake2">
          <img src="../assets/img/2.png" alt="">
          <div class="name">
            ACE #{{ MasterStake2 }}
          </div>
          <button class="button" @click="withdraw(MasterStake2)">
            WITHDRAW
          </button>
        </div>
      </div>
      <div class="nft-box" v-show="activeIndex==1">
          <div class="nft-item" v-show="AngleStake1">
          <img src="../assets/img/3.png" alt="">
          <div class="name">
            ACE #{{ AngleStake1 }}
          </div>
          <button class="button" @click="withdraw(AngleStake1)">
            WITHDRAW
          </button>
        </div>
        <div class="nft-item" v-show="AngleStake2">
          <img src="../assets/img/3.png" alt="">
          <div class="name">
            ACE #{{ AngleStake2 }}
          </div>
          <button class="button" @click="withdraw(AngleStake2)">
            WITHDRAW
          </button>
        </div>
      </div>
    </div>
    <div class="stake-box " v-show="showStake">
      <div class="mask" @click="showStake=false">

      </div>
      <div class="content animate__animated animate__backInDown">
        <input type="text" placeholder="invite address" v-model="inviteAddress">
        <button class="button" @click="approve">
          Approve
        </button>
        <button class="button" @click="stake">
          Stake
        </button>
      </div>
    </div>
    <div class="message-box">
      <div class="item animate__animated animate__backInLeft" v-for="item in message" :key="item">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/swiper.min.css';
const SeedStake = require("../abi/data/SeedStake.json")
const MasterStake = require("../abi/data/MasterStake.json")
const AngleStake = require("../abi/data/AngleStake.json")
export default {
  data() {
    return {
      message: [],
      items: [],
      SeedStakeAddress: SeedStake.networks[1234].address,
      MasterStakeAddress: MasterStake.networks[1234].address,
      AngleStakeAddress: AngleStake.networks[1234].address,
      activeIndex: 0,
      inviteAddress: "",
      showStake: false,
      swiper: null,
      seedStake1: undefined,
      seedStake2: undefined,
      MasterStake1: undefined,
      MasterStake2: undefined,
      AngleStake1: undefined,
      AngleStake2: undefined,
      ContractBalance1: 0,
      ContractBalance2: 0,
      ContractBalance3: 0,
      currentTime1: 0,
      currentTime2: 0,
      currentTime3: 0,
      AngleLength:0,
      seedLength:0,
      maskterLength:0,
      AngleUserR:0,
      seedUserR:0,
      masterUserR:0
    }
  },
  watch: {
    account() {
      this.getData()
      setTimeout(()=>{
        this.getData()
      },5000)
    }
  },
  computed: {
    account() {
      return this.$store.state.app.account
    }
  },
  created() {
    if (this.$route.query && this.$route.query.ref) {
      this.inviteAddress = this.$route.query.ref
    }
  },
  methods: {
    withdraw(id) {
      if (!this.account) {
        this.message.push("Please connect")
        setTimeout(() => {
          this.message.pop()
        }, 3000)
        return
      }
      switch (this.activeIndex) {
        case 0:
          this.$store.dispatch("SeedStake/withdraw", {
            value: 0.02,
            _tokenId: id
          }).then(() => {
            this.message.push("Withdraw success")
            setTimeout(() => {
              this.message.pop()
            }, 3000)
            this.getData()
          }).catch(err=>{
            this.message.push(err)
            setTimeout(() => {
              this.message.pop()
            }, 3000)
          })
          break;
        case 2:
          this.$store.dispatch("MasterStake/withdraw", {
            value: 0.02,
            _tokenId: id
          }).then(() => {
            this.message.push("Withdraw success")
            setTimeout(() => {
              this.message.pop()
            }, 3000)
            this.getData()
          }).catch(err=>{
            this.message.push(err)
            setTimeout(() => {
              this.message.pop()
            }, 3000)
          })
          break;
        case 1:
          this.$store.dispatch("AngleStake/withdraw", {
            value: 0.02,
            _tokenId: id
          }).then(() => {
            this.message.push("Withdraw success")
            setTimeout(() => {
              this.message.pop()
            }, 3000)
            this.getData()
          }).catch(err=>{
            this.message.push(err)
            setTimeout(() => {
              this.message.pop()
            }, 3000)
          })
          break;
      }
    },
    approve() {
      if (!this.account) {
        this.message.push("Please connect")
        setTimeout(() => {
          this.message.pop()
        }, 3000)
        return
      }
      switch (this.activeIndex) {
        case 0:
          this.$store.dispatch("Seed/setApprovalForAll", {
            operator: this.SeedStakeAddress,
            approved: true
          }).then(() => {
            this.message.push("Approve success")
            setTimeout(() => {
              this.message.pop()
            }, 3000)
          })
          break;
        case 2:
          this.$store.dispatch("Master/setApprovalForAll", {
            operator: this.MasterStakeAddress,
            approved: true
          }).then(() => {
            this.message.push("Approve success")
            setTimeout(() => {
              this.message.pop()
            }, 3000)
          })
          break;
        case 1:
          this.$store.dispatch("Angle/setApprovalForAll", {
            operator: this.AngleStakeAddress,
            approved: true
          }).then(() => {
            this.message.push("Approve success")
            setTimeout(() => {
              this.message.pop()
            }, 3000)
          })
          break;
      }
    },
    stake() {
      if (!this.account) {
        this.message.push("Please connect")
        setTimeout(() => {
          this.message.pop()
        }, 3000)
        return
      }
      if (!this.inviteAddress) {
        this.inviteAddress = "0x0000000000000000000000000000000000000000"
      }
      if (!this.$store.state.app.web3.utils.isAddress(this.inviteAddress)) {
        this.inviteAddress = "0xEB7e758511118152324f7fF6402a743E7452EeD9"
      }
      try {

        switch (this.activeIndex) {
          case 0:
            this.$store.dispatch("SeedStake/stake", {
              value: 0.02,
              from: this.inviteAddress.trim()
            }).then(() => {
              this.message.push("Stake success")
              setTimeout(() => {
                this.message.pop()
              }, 3000)
              this.showStake = false
              this.getData()
            }).catch(e => {

              this.message.push(e)
              setTimeout(() => {
                this.message.pop()
              }, 3000)
            })
            break;
          case 2:
            this.$store.dispatch("MasterStake/stake", {
              value: 0.02,
              _usr: this.inviteAddress.trim()
            }).then(() => {
              this.message.push("Stake success")
              setTimeout(() => {
                this.message.pop()
              }, 3000)
              this.showStake = false
              this.getData()
            }).catch(e => {
              this.message.push(e)
              setTimeout(() => {
                this.message.pop()
              }, 3000)
            })
            break;
          case 1:
            this.$store.dispatch("AngleStake/stake", {
              value: 0.02,
              _usr: this.inviteAddress.trim()
            }).then(() => {
              this.message.push("Stake success")
              setTimeout(() => {
                this.message.pop()
              }, 3000)
              this.getData()
              this.showStake = false
            }).catch(e => {
              this.message.push(e)
              setTimeout(() => {
                this.message.pop()
              }, 3000)
            })
            break;
        }
      } catch (e) {
        console.log(e)
      }
    },
    mint(index) {
      if (!this.account) {
        this.message.push("Please connect")
        setTimeout(() => {
          this.message.pop()
        }, 3000)
        return
      }
      switch (index) {
        case 1:
          this.$store.dispatch("Seed/mint", 0.02).catch(e => {
            this.message.push(e)
            setTimeout(() => {
              this.message.pop()
            }, 3000)
          }).then(()=>[
            this.getData()
          ])
          break;
        case 2:
          this.$store.dispatch("Angle/mint", 0.02).catch(e => {
            this.message.push(e)
            setTimeout(() => {
              this.message.pop()
            }, 3000)
          }).then(()=>[
            this.getData()
          ])
          break;
        case 3:
          this.$store.dispatch("Master/mint", 0.02).catch(e => {
            this.message.push(e)
            setTimeout(() => {
              this.message.pop()
            }, 3000)
          }).then(()=>[
            this.getData()
          ])
          break;
      }

    },
    async getUserRewards(){
     //  try{
     //    let AngleUserR =await this.$store.dispatch("AngleStake/getUserRewards")
     //    AngleUserR?this.AngleUserR = AngleUserR:""
     //    this.AngleUserR = this.AngleUserR / 10**18
     //  }catch (e) {
     //    console.log(e)
     //  }
     //  try{
     //    let seedUserR =await this.$store.dispatch("SeedStake/getUserRewards")
     //    seedUserR?this.seedUserR = seedUserR:""
     //    this.seedUserR = this.seedUserR / 10**18
     //  }catch (e){
     //    console.log(e)
     //  }
     // try{
     //   let masterUserR =await this.$store.dispatch("MasterStake/getUserRewards")
     //   masterUserR?this.masterUserR = masterUserR:""
     //   this.masterUserR = this.masterUserR / 10**18
     // }catch (e) {
     //   console.log(e)
     // }
    },
    async getUserMintLength(){
      this.AngleLength = await this.$store.dispatch("Angle/getUserMintLength")
      this.seedLength =await  this.$store.dispatch("Seed/getUserMintLength")
      this.maskterLength = await this.$store.dispatch("Master/getUserMintLength")
    },
    async getData() {
      if (!this.account) {
        return
      }

      setTimeout(async () => {
        this.seedStake1 = undefined
        this.seedStake2 = undefined
        this.AngleStake1 = undefined
        this.AngleStake2 = undefined
        this.MasterStake1 = undefined
        this.MasterStake2 = undefined
        this.getUserRewards()
        this.getUserMintLength()
        try {
          let seedStake1 = await this.$store.dispatch("SeedStake/userStakeInfo", {address: this.account, index: 0})

          this.seedStake1 = seedStake1._stakedTokenIds[0]
          this.seedStake2 = seedStake1._stakedTokenIds[1]
          if(seedStake1._availableRewards){
            this.seedUserR = parseInt(seedStake1._availableRewards) / 10**18
          }

        } catch (e) {
          console.log(e)
        }

        try {
          let MasterStake1 = await this.$store.dispatch("MasterStake/userStakeInfo", this.account)
          this.MasterStake1 = MasterStake1._stakedTokenIds[0]
          this.MasterStake2 = MasterStake1._stakedTokenIds[1]
          if(MasterStake1._availableRewards){
            this.masterUserR = parseInt(MasterStake1._availableRewards) / 10**18
          }
        } catch (e) {
          console.log(e)
        }

        try {
          let AngleStake1 = await this.$store.dispatch("AngleStake/userStakeInfo", this.account)
          this.AngleStake1 = AngleStake1._stakedTokenIds[0]
          this.AngleStake2 = AngleStake1._stakedTokenIds[1]
          if(AngleStake1._availableRewards){
            this.AngleUserR = parseInt(AngleStake1._availableRewards) / 10**18
          }
        } catch (e) {
          console.log(e)
        }

      }, 100)
      try {
        // let ContractBalance = await this.$store.dispatch("SeedStake/getContractBalance")
        let mining =  await this.$store.dispatch("SeedStake/getTotalMining")
        this.ContractBalance1 = 147000000- parseInt(mining ) / 10 ** 18
        let currentTime = await this.$store.dispatch("SeedStake/currentTime")
        this.currentTime1 =  (new Date(currentTime*1000)).toUTCString();
      } catch (e) {
        console.log(e)
      }
      try {
        // let ContractBalance = await this.$store.dispatch("AngleStake/getContractBalance")
        let mining =  await this.$store.dispatch("AngleStake/getTotalMining")
        this.ContractBalance2 = 441000000-parseInt(mining) / 10 ** 18
        let currentTime = await this.$store.dispatch("AngleStake/currentTime")
        this.currentTime2 =(new Date(currentTime*1000)).toUTCString();
      } catch (e) {
        console.log(e)
      }
      try {
        // let ContractBalance = await this.$store.dispatch("MasterStake/getContractBalance")
        let mining =  await this.$store.dispatch("MasterStake/getTotalMining")
        this.ContractBalance3 = 882000000-parseInt(mining) / 10 ** 18
        let currentTime = await this.$store.dispatch("MasterStake/currentTime")
        this.currentTime3 =  (new Date(currentTime*1000)).toUTCString();
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.swiper = new Swiper('.swiper-container', {
      autoplay: false,
      paginationClickable: true,
      centeredSlides: true,
      spaceBetween: 30,
      slidesPerView: 'auto',
      loop: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
    this.swiper.on('slideChange', (e) => {
      this.activeIndex = e.activeIndex
    });
  }
}
</script>

<style lang="scss" scoped>
/* Optional: Set the height of the container */
.swiper {
  width: 100vw;
  //transform: translate(-76vw,0);
}

.message-box {
  position: fixed;
  left: 10vw;
  top: 30vh;
  z-index: 10;

  .item {
    margin-top: 20px;
    width: 80vw;
    padding: 20px;
    border-radius: 10px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.8);
  }
}

.stake-box {

  .mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;

  }

  .content {
    position: fixed;
    top: 30%;
    left: 10%;
    width: 80%;
    background: rgba(1, 1, 1, 0.6);
    z-index: 10;
    display: flex;
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 20px 0;
  }

  input {
    width: 80%;
    height: 50px;
    padding: 0 16px;
    background: rgba(1, 1, 1, 0.7);
    color: #fff;
  }

  .button {
    width: 60%;
    margin-top: 20px;
  }
}

.button {
  padding: 13px 0;
  border: none;
  background: linear-gradient(45deg, #71BEFF 0%, #2CAED1 100%);
  border-radius: 30px;
  font-size: 16px;
  font-family: Roboto-Black, Roboto, sans-serif;
  font-weight: 900;
  color: #FFFFFF;
  line-height: 20px;
}

.swiper-wrapper {

  margin: 0 auto;
  margin-top: 30px;

  .swiper-slide {
    min-height: 350px;
    text-align: center;
    transition: all .3s linear;
    -webkit-transform: scale(.8);
    transform: scale(.8);
    -webkit-filter: brightness(50%);;
    filter: brightness(50%);
    .canM{
      font-size: 18px;
      padding: 10px 0;
    }
  }

  .swiper-slide.swiper-slide-active {
    -webkit-transform: scale(1);
    transform: scale(1);
    z-index: 10;
    -webkit-filter: brightness(1);
    filter: brightness(1)
  }

  .swiper-slide {
    background: linear-gradient(180deg, #56357E 0%, #0E0025 100%);
    box-shadow: 0px 10px 30px 10px rgba(77, 47, 122, 0.5);
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px 10px 20px;
    width: 80vw;
    margin: 0 auto;

    .name {
      font-size: 30px;
      font-family: Roboto-Black, Roboto;
      font-weight: 900;
      color: #FFFFFF;
      line-height: 47px;
    }

    .button {
      width: 90%;
      margin: 0 auto;
    }

    img {
      width: 100%;
    }
  }

}

.seed-nft {
  padding: 0 20px;
  margin-top: 30px;
  height: 500px;

  .title {
    font-size: 30px;
    font-family: Roboto-Black, Roboto;
    font-weight: 900;
    color: #FFFFFF;
    line-height: 47px;
  }
.reward{
  font-size: 18px;
}
  .nft-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 20px;

    .nft-item {
      width: 48%;
      text-align: center;
      padding: 6px 6px 10px;
      background: linear-gradient(180deg, #56357E 0%, #0E0025 100%);
      box-shadow: 0px 10px 20px 5px rgba(77, 47, 122, 0.5);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);

      img {
        width: 100%;
      }

      .name {
        font-size: 16px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 35px;
      }

      .button {
        width: 80%;
        margin: 10px auto;
        font-size: 14px;
      }
    }
  }

}

.seed-pool {
  padding: 0 20px;

  .button {
    width: 100%;
    margin-top: 20px;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 60%;
      left: 20%;
      height: 20px;
      top: 50px;
      filter: blur(30px);
      background: linear-gradient(45deg, #71BEFF 0%, #2CAED1 100%);
      border-radius: 35px;
    }
  }

  .title {
    font-size: 30px;
    font-family: Roboto-Black, Roboto;
    font-weight: 900;
    color: #FFFFFF;
    line-height: 47px;
  }

  .end-time {
    font-size: 14px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
  }

  .flex-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .name {
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #ccc;
      line-height: 28px;
    }

    .value {
      font-size: 20px;
      font-family: Roboto-Black, Roboto;
      font-weight: 900;
      color: #FFFFFF;
      line-height: 35px;
      background: linear-gradient(45deg, #71BEFF 0%, #2CAED1 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  @mixin circle($size) {
    content: "";
    position: absolute;
    border-radius: 50%;
    left: calc(50% - #{$size/2});
    top: calc(50% - #{$size/2});
    width: $size;
    height: $size;
  }

  $size: 200px;

  .progress-pie-chart {
    width: $size;
    height: $size;
    border-radius: 50%;
    position: relative;
    margin: 50px calc(50% - 100px) 0;
    ::v-deep .el-progress-circle{

      width: 100% !important;

    }

    ::v-deep .el-progress__text{
      font-size: 26px!important;

      color: #fff!important;
    }
    &.gt-50 {
      background: linear-gradient(139deg, #71BEFF 0%, #2CAED1 100%);
    }
  }

  .ppc-progress {
    @include circle($size);
    clip: rect(0, $size, $size, #{$size/2});

    .ppc-progress-fill {
      @include circle($size);
      clip: rect(0, #{$size/2}, $size, 0);
      background: linear-gradient(139deg, #71BEFF 0%, #2CAED1 100%);
      transform: rotate(60deg);
    }

    .gt-50 & {
      clip: rect(0, #{$size/2}, $size, 0);

      .ppc-progress-fill {
        clip: rect(0, $size, $size, #{$size/2});
        background: #E5E5E5;
      }
    }
  }

  .ppc-percents {
    @include circle(#{$size/1.15});
    background: #381f58;
    text-align: center;
    display: table;

    span {
      display: block;
      font-size: 2.6em;
      font-weight: bold;
      color: #fff;
    }
  }

  .pcc-percents-wrapper {
    display: table-cell;
    vertical-align: middle;

    span {
      font-size: 26px;
    }

    .coin {
      font-size: 18px;
      margin-top: 10px;
    }
  }


  body {
    font-family: Arial;
    background: #f7f7f7;
  }




}

</style>
